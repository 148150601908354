import { useEffect } from "react";
import Axios from "axios";

const Navegacion = ({ userId }) => {
  useEffect(() => {
    const updateHeartbeat = () => {
      Axios.post("http://localhost:3001/heartbeat", { id: userId })
        .then(() => console.log("Estado actualizado"))
        .catch((error) => console.error("Error al actualizar estado:", error));
    };

    // Enviar heartbeat cada 30 segundos
    const interval = setInterval(updateHeartbeat, 30000);

    // Actualizar al cargar la página
    updateHeartbeat();

    // Desconectar al cerrar pestaña
    const handleBeforeUnload = () => {
      Axios.post("http://localhost:3001/logout", { id: userId });
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      clearInterval(interval);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [userId]);

  return <nav>/* Aquí va tu barra de navegación */</nav>;
};

export default Navegacion;
