import React, { useState, useEffect } from "react";
import Axios from "axios";

const Inicio = ({ userId }) => {
  const [usuariosConectados, setUsuariosConectados] = useState([]);

  useEffect(() => {
    // Cargar usuarios conectados
    Axios.get("http://localhost:3001/usuarios-conectados")
      .then((response) => {
        setUsuariosConectados(response.data);
      })
      .catch((error) => {
        console.error("Error al obtener usuarios conectados:", error);
      });
  }, []);

  return (
    <div>
      <h1>Inicio</h1>
      <h2>Usuarios conectados:</h2>
      <ul>
        {usuariosConectados.map((usuario) => (
          <li key={usuario.id}>
            {usuario.usuario} - {usuario.estado}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Inicio;
