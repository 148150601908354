import React from 'react';
import { Link } from 'react-router-dom';

const MenuPrincipal = () => {
  return (
    <div className="container text-center mt-5">
      <h1>Bienvenido al Sistema</h1>
      <p>Seleccione una opción para continuar:</p>
      <div className="d-grid gap-2 col-6 mx-auto">
        <Link to="/login" className="btn btn-primary">Iniciar Sesión</Link>
        <Link to="/registro" className="btn btn-secondary">Registrarse</Link>

      </div>
    </div>
  );
};

export default MenuPrincipal;
