import React, { useState } from "react";
import Axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [usuario, setUsuario] = useState("");
  const [contrasenia, setContrasenia] = useState("");
  const [mensajeError, setMensajeError] = useState("");

  const navigate = useNavigate(); // Usamos useNavigate para redirigir al usuario

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validar que los campos no estén vacíos
    if (!usuario || !contrasenia) {
      setMensajeError("Todos los campos son obligatorios.");
      return;
    }

    // Enviar los datos al backend para hacer login
    Axios.post("http://localhost:3001/login", {
      usuario,
      contrasenia,
    })
      .then((response) => {
        // Si el login es exitoso, redirigimos al usuario
        Swal.fire({
          title: "Inicio de sesión exitoso",
          text: "Bienvenido a Minichiba",
          icon: "success",
        }).then(() => {

          


          navigate("/inicio"); // Redirige al usuario a la página de Inicio
        });
      })
      .catch((error) => {
        console.error("Error de inicio de sesión:", error.response ? error.response.data : error.message);
        Swal.fire({
          title: "Error",
          text: "Nombre de usuario o contraseña incorrectos",
          icon: "error",
        });
      });
  };

  return (
    <div className="container">
      <h2>Iniciar sesión</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Nombre de usuario:</label>
          <input
            type="text"
            className="form-control"
            value={usuario}
            onChange={(e) => setUsuario(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>Contraseña:</label>
          <input
            type="password"
            className="form-control"
            value={contrasenia}
            onChange={(e) => setContrasenia(e.target.value)}
            required
          />
        </div>

        {mensajeError && <p className="text-danger">{mensajeError}</p>}

        <br></br>

        <button type="submit" className="btn btn-primary">
          Iniciar sesión
        </button>
      </form>
    </div>
  );
};

export default Login;
