import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MenuPrincipal from "./pages/MenuPrincipal";
import Registro from "./pages/Registro";
import Login from "./pages/Login";

import Inicio from "./pages/Inicio"; // Importamos la nueva página
import Navegacion from "./components/Navegacion"; // Importamos el componente Navegacion
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [userId, setUserId] = useState(null); // Almacenamos el ID del usuario conectado

  useEffect(() => {
    // Recuperamos el usuario de localStorage (si existe)
    const userData = JSON.parse(localStorage.getItem("user"));
    if (userData) {
      setUserId(userData.id); // Configuramos el ID del usuario
    }
  }, []);

  return (
    <Router>
      {userId && <Navegacion userId={userId} />} {/* Mostramos la barra si hay usuario */}
      <Routes>
        <Route path="/" element={<MenuPrincipal />} />
        <Route path="/registro" element={<Registro />} />
        <Route path="/login" element={<Login />} />
        
        <Route
          path="/inicio"
          element={<Inicio userId={userId} />} // Pasamos el ID al inicio
        />
      </Routes>
    </Router>
  );
}

export default App;
